import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Container from '../Container';
import { isMessageDefinition } from '../../utils/message';
import styles from './Hero.module.scss';

function MainPageHero(props) {
  const { title, backgroundImage: BackgroundImage, intl } = props;

  const renderTitle = () =>
    isMessageDefinition(title)
      ? intl.formatMessage(title, title.values)
      : title;

  return (
    <div className={styles.mainPageHeroContainer}>
      <BackgroundImage
        className={styles.mainPageHeroBackgroundImage}
        style={{ position: 'absolute' }}
        critical
        fadeIn={false}
      />
      <Container className={styles.mainPageHeroContentContainer}>
        <div className={styles.mainPageHeroTitle}>{renderTitle()}</div>
      </Container>
    </div>
  );
}

MainPageHero.propTypes = {
  title: PropTypes.node,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  backgroundImage: PropTypes.elementType.isRequired
};

MainPageHero.defaultProps = {
  title: null
};

export default injectIntl(MainPageHero);
