import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage } from 'react-intl';
import App from '../App';
import MainPageHero from '../components/Hero/MainPageHero';
import ProjectSection from '../components/ProjectSection';
import LargeQuotationSection from '../components/QuotationSection/LargeQuotationSection';
import DoubleTextSection from '../components/DoubleTextSection';
import TextSection from '../components/TextSection';
import ContactSection from '../components/ContactSection';
import Link from '../components/Link';
import CircleChartSection from '../components/CircleChartSection';
import MainPageHeroImage from '../gatsbyImages/MainPageHeroImage';
import CourtlyQuoteBackgroundImage from '../gatsbyImages/CourtlyQuoteBackgroundImage';
import CourtlyMockupImage from '../gatsbyImages/CourtlyMockupImage';
import NexogenMockupImage from '../gatsbyImages/NexogenMockupImage';
import NamedropMockupImage from '../gatsbyImages/NamedropMockupImage';
import GreenSquareIcon from '../assets/icons/GreenSquareIcon';
import BlueDotsIcon from '../assets/icons/BlueDotsIcon';
import emilioBarajas from '../assets/images/emilio-barajas-avatar.png';
import josephTerpstra from '../assets/images/joseph-terpstra-avatar.png';
import leHoangGiang from '../assets/images/le-hoang-giang-avatar.png';
import * as paths from '../paths';
import styles from './pages.module.scss';

const messages = defineMessages({
  heroTitle1: {
    id: 'mainPage.heroTitle1',
    defaultMessage: 'Discover '
  },
  heroTitle2: {
    id: 'mainPage.heroTitle2',
    defaultMessage: 'valuable {br} use cases. {br}'
  },
  heroTitle3: {
    id: 'mainPage.heroTitle3',
    defaultMessage: 'Build '
  },
  heroTitle4: {
    id: 'mainPage.heroTitle4',
    defaultMessage: 'software that scales. {br}'
  },
  heroTitle5: {
    id: 'mainPage.heroTitle5',
    defaultMessage: 'Move '
  },
  heroTitle6: {
    id: 'mainPage.heroTitle6',
    defaultMessage: 'industries.'
  },
  quoteText: {
    id: 'mainPage.quoteText',
    defaultMessage:
      'The StreamBright team provided a complete solution that was exactly what I needed to quickly put a product on the market. Once we mapped out the roadmap together, my team and I were able to focus on business development without constantly having to check back in on the development.'
  },
  courtlyTitle: {
    id: 'mainPage.courtlyTitle',
    defaultMessage: 'Courtly x Streambright'
  },
  courtlyDescription: {
    id: 'mainPage.courtlyDescription',
    defaultMessage:
      'The first phase of our engagement resulted in crystal clear user flows and an intuitive interface. Within 6 months, StreamBright delivered a web and mobile platform with five major features, including segmented emailing, booking management, customer attendance, direct payment and lesson plans.'
  },
  nexogenTitle: {
    id: 'mainPage.nexogenTitle',
    defaultMessage: 'Nexogen x StreamBright'
  },
  nexogenDescription: {
    id: 'mainPage.nexogenDescription',
    defaultMessage:
      'Our cooperation with the Streambright team is really smooth - we agree on scope and the team gets it done. We especially value the pro-activity from the developer level: we can count on the honesty and expertise of all the team members from junior to senior level. They raise issues about the scope and recommend solutions to consider. We have the piece of mind that the team only executes on tasks that really thought through and make sense.'
  },
  namedropTitle: {
    id: 'mainPage.namedropTitle',
    defaultMessage: 'Namedrop x Streambright'
  },
  namedropDescription: {
    id: 'mainPage.namedropDescription',
    defaultMessage:
      'Adam Jermann and the entire team at StreamBright has exceeded every expectation I had when it came to their software development, quick response times and overall professionalism. Simply put, I strongly believe that any executive/startup founder looking for a development team that brings the most value, Streambright is THE answer, especially since I personally struggled and lost a lot of money with previous offshore teams.'
  },
  button: {
    id: 'mainPage.button',
    defaultMessage: 'Read case study'
  },
  quotationButton: {
    id: 'mainPage.quotationButton',
    defaultMessage: 'Read Courtly’s Story'
  },
  firstTextSectionTitle: {
    id: 'mainPage.firstTextSectionTitle',
    defaultMessage: 'The StreamBright Approach'
  },
  firstTextSectionDescription: {
    id: 'mainPage.firstTextSectionDescription',
    defaultMessage:
      'If we’re talking about disruptive software, delivering quality code on time and {br} on budget isn’t even half the battle.'
  },
  secondTextSectionTitle: {
    id: 'mainPage.secondTextSectionTitle',
    defaultMessage: 'Check out our past projects'
  },
  secondTextSectionDescription: {
    id: 'mainPage.secondTextSectionDescription',
    defaultMessage:
      'Take a look at our past work in over 12 complex verticals. The results and the experience have resulted in a {br} 100% referral rate from our partners.'
  },
  contactSectionTitle: {
    id: 'mainPage.contactSectionTitle',
    defaultMessage: 'How can we help you?'
  },
  contactSectionDescription: {
    id: 'mainPage.contactSectionDescription',
    defaultMessage: `Tell us the 1,000 foot view of your project and we'll put our brains, tools and {br} processes in place to make it a reality.`
  }
});

function MainPage() {
  return (
    <App>
      <Helmet>
        <title>Streambright - World Class Software and Teams</title>
        <meta
          name="description"
          content="You bring your vision, business concept and project requirements. We’ll bring the technical brains, hiccup-free development process and coffee."
        />
      </Helmet>
      <MainPageHero
        title={
          <div className={styles.textContainer}>
            <span className={styles.redText}>
              <FormattedMessage {...messages.heroTitle1} />
            </span>
            <FormattedMessage
              {...messages.heroTitle2}
              values={{ br: <br /> }}
            />
            <span className={styles.redText}>
              <FormattedMessage {...messages.heroTitle3} />
            </span>
            <FormattedMessage
              {...messages.heroTitle4}
              values={{ br: <br /> }}
            />
            <span className={styles.redText}>
              <FormattedMessage {...messages.heroTitle5} />
            </span>
            <FormattedMessage {...messages.heroTitle6} />
          </div>
        }
        backgroundImage={MainPageHeroImage}
      />
      <DoubleTextSection />
      <TextSection
        title={messages.firstTextSectionTitle}
        description={
          <FormattedMessage
            {...messages.firstTextSectionDescription}
            values={{ br: <br /> }}
          />
        }
      />
      <CircleChartSection />
      <LargeQuotationSection
        backgroundImage={CourtlyQuoteBackgroundImage}
        description={messages.quoteText}
        avatar={<img src={emilioBarajas} alt="Emilio Barajas" />}
        authorName="Emilio Barajas"
        jobTitle="Founder and CEO"
      >
        <Link variant="outlined" color="secondary" to={paths.courtly()}>
          <FormattedMessage {...messages.quotationButton} />
        </Link>
      </LargeQuotationSection>
      <TextSection
        title={messages.secondTextSectionTitle}
        description={
          <FormattedMessage
            {...messages.secondTextSectionDescription}
            values={{ br: <br /> }}
          />
        }
      />
      <ProjectSection
        image={CourtlyMockupImage}
        imageClassName={styles.courtlyMockup}
        title={messages.courtlyTitle}
        description={messages.courtlyDescription}
        avatar={<img src={emilioBarajas} alt="Emilio Barajas" />}
        authorName="Emilio Barajas"
        jobTitle="CEO"
        icon={
          <div className={styles.topIcon}>
            <BlueDotsIcon />
          </div>
        }
        quote
        to={paths.courtly()}
        buttonText={messages.button}
      />
      <ProjectSection
        image={NexogenMockupImage}
        imageClassName={styles.nexogenMockup}
        title={messages.nexogenTitle}
        description={messages.nexogenDescription}
        avatar={<img src={leHoangGiang} alt="Le Hoang Giang" />}
        authorName="Le Hoang Giang"
        jobTitle="Co-Founder"
        reverse
        variant="secondary"
        icon={
          <div className={styles.bottomIcon}>
            <GreenSquareIcon />
          </div>
        }
        quote
        to={paths.nexogen()}
        buttonText={messages.button}
      />
      <ProjectSection
        image={NamedropMockupImage}
        imageClassName={styles.namedropMockup}
        title={<FormattedMessage {...messages.namedropTitle} />}
        description={<FormattedMessage {...messages.namedropDescription} />}
        avatar={<img src={josephTerpstra} alt="Joseph Terpstra" />}
        authorName="Joseph Terpstra"
        jobTitle="Founder and CEO"
        quote
        to={paths.namedrop()}
        buttonText={messages.button}
      />
      <div id="Contact">
        <ContactSection
          title={messages.contactSectionTitle}
          description={
            <FormattedMessage
              {...messages.contactSectionDescription}
              values={{ br: <br /> }}
            />
          }
        />
      </div>
    </App>
  );
}

export default MainPage;
