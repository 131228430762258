import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Container from '../Container';
import GreenSquareIcon from '../../assets/icons/GreenSquareIcon';
import BlueDotsIcon from '../../assets/icons/BlueDotsIcon';
import * as paths from '../../paths';
import Link from '../Link';
import styles from './DoubleTextSection.module.scss';

const messages = defineMessages({
  doubleTextSectionTitleLeft: {
    id: 'doubleTextSection.doubleTextSectionTitleLeft',
    defaultMessage: 'Word-Class {br} Software Development'
  },
  doubleTextSectionTitleRight: {
    id: 'doubleTextSection.doubleTextSectionTitleRight',
    defaultMessage: 'On-Demand {br} Tech Expertise'
  },
  doubleTextSectionDescriptionLeft: {
    id: 'doubleTextSection.doubleTextSectionDescriptionLeft',
    defaultMessage:
      'You bring your vision, business concept and project requirements. We’ll bring the technical brains, hiccup-free development process and coffee.'
  },
  doubleTextSectionDescriptionRight: {
    id: 'doubleTextSection.doubleTextSectionDescriptionRight',
    defaultMessage:
      'The days of scrambling to hire last minute are over. A pool of qualified engineers and teams is at your fingertips.'
  },
  buttonLeft: {
    id: 'doubleTextSection.buttonLeft',
    defaultMessage: 'Build your vision'
  },
  buttonRight: {
    id: 'doubleTextSection.buttonRight',
    defaultMessage: 'Tap into our network'
  }
});

function TextSection() {
  return (
    <Container className={styles.container}>
      <div className={styles.contentLeft}>
        <div className={styles.title}>
          <FormattedMessage
            {...messages.doubleTextSectionTitleLeft}
            values={{ br: <br /> }}
          />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.doubleTextSectionDescriptionLeft} />
        </div>
        <div className={styles.button}>
          <Link
            variant="contained"
            color="primary"
            to={paths.softwareDevelopmentPage()}
          >
            <FormattedMessage {...messages.buttonLeft} />
          </Link>
        </div>
        <div className={styles.iconLeft}>
          <GreenSquareIcon />
        </div>
      </div>
      <div className={styles.contentRight}>
        <div className={styles.title}>
          <FormattedMessage
            {...messages.doubleTextSectionTitleRight}
            values={{ br: <br /> }}
          />
        </div>
        <div className={styles.description}>
          <FormattedMessage {...messages.doubleTextSectionDescriptionRight} />
        </div>
        <div className={styles.button}>
          <Link
            variant="contained"
            color="secondary"
            to={paths.teamExtensionPage()}
          >
            <FormattedMessage {...messages.buttonRight} />
          </Link>
        </div>
        <div className={styles.iconRight}>
          <BlueDotsIcon />
        </div>
      </div>
    </Container>
  );
}

export default TextSection;
