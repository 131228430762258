import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Container from '../Container';
import { isMessageDefinition } from '../../utils/message';
import styles from './TextSection.module.scss';

function TextSection(props) {
  const { title, description, intl } = props;

  const renderDescription = () =>
    isMessageDefinition(description)
      ? intl.formatMessage(description, description.values)
      : description;

  const renderTitle = () =>
    isMessageDefinition(title)
      ? intl.formatMessage(title, title.values)
      : title;

  return (
    <Container>
      <div className={styles.contentContainer}>
        <h1 className={styles.title}>{renderTitle()}</h1>
        <p className={styles.description}>{renderDescription()}</p>
      </div>
    </Container>
  );
}

TextSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({})]),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired
};

TextSection.defaultProps = {
  title: null,
  description: null
};

export default injectIntl(TextSection);
