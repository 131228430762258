import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const CourtlyQuoteBackgroundImage = props => {
  const data = useStaticQuery(graphql`
    query {
      courtlyQuoteBackgroundImage: file(
        relativePath: { eq: "courtly-quote-background.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Img
      fluid={data.courtlyQuoteBackgroundImage.childImageSharp.fluid}
      {...props}
    />
  );
};

export default CourtlyQuoteBackgroundImage;
