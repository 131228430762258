import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const CircleChartImage = props => {
  const data = useStaticQuery(graphql`
    query {
      circleChartImage: file(relativePath: { eq: "circle-chart.png" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return <Img fluid={data.circleChartImage.childImageSharp.fluid} {...props} />;
};

export default CircleChartImage;
