import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { ParallaxBanner } from 'react-scroll-parallax';
import Quotation from './Quotation';
import styles from './QuotationSection.module.scss';

function LargeQuotationSection(props) {
  const { backgroundImage: BackgroundImage, ...rest } = props;

  return (
    <div className={styles.largeQuotationSectionContainer}>
      <ParallaxBanner
        layers={[
          {
            children: (
              <BackgroundImage
                className={styles.backgroundImage}
                style={{ position: 'absolute' }}
                critical
                fadeIn={false}
              />
            ),
            amount: 0.15
          }
        ]}
        style={{
          height: '700px'
        }}
      >
        <Quotation {...rest} />
      </ParallaxBanner>
    </div>
  );
}

LargeQuotationSection.propTypes = {
  backgroundImage: PropTypes.elementType.isRequired
};

export default injectIntl(LargeQuotationSection);
