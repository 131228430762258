import React, { useRef, useEffect, useCallback, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import cn from 'classnames';
import Container from '../Container';
import SingleSquareIcon from '../../assets/icons/SingleSquareIcon';
import CircleChartImage from '../../gatsbyImages/CircleChartImage';
import * as paths from '../../paths';
import Link from '../Link';
import styles from './CircleChartSection.module.scss';

const messages = defineMessages({
  title1: {
    id: 'circleChartSection.title1',
    defaultMessage: 'High-quality software'
  },
  description1: {
    id: 'circleChartSection.description1',
    defaultMessage:
      'Humans are at the center of our process. Be it the spec, UX/UI research, development, delivery — our focus is always on making sure both our clients and their users love the end product.'
  },
  title2: {
    id: 'circleChartSection.title2',
    defaultMessage: 'Agile process'
  },
  description2: {
    id: 'circleChartSection.description2',
    defaultMessage:
      'Sometimes the best process is a combination of two good ones. We use the Scrum-Ban Method to deliver high-quality code on time, while staying flexible enough to explore new ideas.'
  },
  title3: {
    id: 'circleChartSection.title3',
    defaultMessage: 'Team expertise'
  },
  description3: {
    id: 'circleChartSection.description3',
    defaultMessage:
      'Our software engineers solve challenges with elegant solutions and best practices. And our handover process makes sure your team can jump right in where we left off.'
  },
  button: {
    id: 'circleChartSection.button',
    defaultMessage: 'Check out our team'
  }
});

function CircleChartSection() {
  const section1 = useRef();
  const section2 = useRef();
  const section3 = useRef();
  const slice1 = useRef();
  const slice2 = useRef();
  const slice3 = useRef();

  const [activeSection, setActiveSection] = useState(1);

  const handleSection1Select = useCallback(() => {
    setActiveSection(1);
  }, []);
  const handleSection2Select = useCallback(() => {
    setActiveSection(2);
  }, []);
  const handleSection3Select = useCallback(() => {
    setActiveSection(3);
  }, []);

  useEffect(() => {
    section1.current.addEventListener('mouseenter', handleSection1Select);
    slice1.current.addEventListener('mouseenter', handleSection1Select);

    section2.current.addEventListener('mouseenter', handleSection2Select);
    slice2.current.addEventListener('mouseenter', handleSection2Select);

    section3.current.addEventListener('mouseenter', handleSection3Select);
    slice3.current.addEventListener('mouseenter', handleSection3Select);

    return () => {
      section1.current.removeEventListener('mouseenter', handleSection1Select);
      slice1.current.removeEventListener('mouseenter', handleSection1Select);

      section2.current.removeEventListener('mouseenter', handleSection2Select);
      slice2.current.removeEventListener('mouseenter', handleSection2Select);

      section3.current.removeEventListener('mouseenter', handleSection3Select);
      slice3.current.removeEventListener('mouseenter', handleSection3Select);
    };
  }, []);

  return (
    <div>
      <Container className={styles.container}>
        <div className={styles.imageContainer}>
          <CircleChartImage
            className={styles.image}
            imgStyle={{ objectFit: 'contain' }}
            critical
            fadeIn={false}
          />
          <div
            className={cn(styles.slice3, {
              [styles.activeSlice]: activeSection === 3
            })}
            ref={slice3}
          />
          <div
            className={cn(styles.slice2, {
              [styles.activeSlice]: activeSection === 2
            })}
            ref={slice2}
          />
          <div
            className={cn(styles.slice1, {
              [styles.activeSlice]: activeSection === 1
            })}
            ref={slice1}
          />
        </div>
        <div className={styles.textContainer}>
          <div
            className={cn(styles.text, {
              [styles.activeSection]: activeSection === 1
            })}
            ref={section1}
          >
            <div className={styles.inline}>
              <SingleSquareIcon fill="#FE6B6F" />
              <div className={styles.redTitle}>
                <FormattedMessage {...messages.title1} />
              </div>
            </div>
            <div className={styles.description}>
              <FormattedMessage {...messages.description1} />
            </div>
          </div>
          <div
            className={cn(styles.text, {
              [styles.activeSection]: activeSection === 2
            })}
            ref={section2}
          >
            <div className={styles.inline}>
              <SingleSquareIcon />
              <div className={styles.greenTitle}>
                <FormattedMessage {...messages.title2} />
              </div>
            </div>
            <div className={styles.description}>
              <FormattedMessage {...messages.description2} />
            </div>
          </div>
          <div
            className={cn(styles.text, {
              [styles.activeSection]: activeSection === 3
            })}
            ref={section3}
          >
            <div className={styles.inline}>
              <SingleSquareIcon fill="#2A4376" />
              <div className={styles.blueTitle}>
                <FormattedMessage {...messages.title3} />
              </div>
            </div>
            <div className={styles.description}>
              <FormattedMessage {...messages.description3} />
            </div>
          </div>
          <div className={styles.button}>
            <Link variant="contained" color="primary" to={paths.teamPage()}>
              <FormattedMessage {...messages.button} />
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CircleChartSection;
