import React from 'react';
import PropTypes from 'prop-types';

function SingleGreenSquareIcon(props) {
  const { fill } = props;

  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-127.000000, -1000.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g transform="translate(127.000000, 1000.000000)">
            <rect x="0" y="0" width="12" height="12" />
          </g>
        </g>
      </g>
    </svg>
  );
}

SingleGreenSquareIcon.propTypes = {
  fill: PropTypes.string
};

SingleGreenSquareIcon.defaultProps = {
  fill: '#3AE1AC'
};

export default SingleGreenSquareIcon;
